import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useFirebase } from "gatsby-plugin-firebase"

import {
  FaBusinessTime,
} from 'react-icons/fa';

import { InvestorsForm } from '../forms/InvestorsForm';

const InvestorsPageTemplate = ({ title, subtitle, meta_title, meta_description }) => {
  useFirebase(firebase => {
    firebase
      .analytics()
      .logEvent("investors_page")
  }, [])

  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>

    <section className='hero is-lavender is-bold'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-vcentered'>

            <div>
              <div className='section'>
                <h1 className='title has-text-weight-semibold is-size-3'>{title}</h1>
                <h2 className='is-size-5 mb-1'>{subtitle}</h2>

                <h2 className='has-text-justified mt-1 is-size-6'>
                  We are currently open for people who have the same vision to join us. 
                </h2>

                <h2 className='has-text-justified mt-1 is-size-6'>
                  If you are frustrated at how the world is going and you want to make some changes for a good cause, please flick us a message.
                </h2>
                
                <h2 className='has-text-justified mt-1 is-size-6'>
                  Whether you are a pharmacist or a pharmacist at heart, doctor, optometrist or just an investor with the right mindset.
                </h2>

                <h2 className='has-text-justified mt-1 is-size-6'>
                  You do not need to have anything, just pure intentions to help the Australian community is enough.
                </h2>
              </div>
            </div>

            <div className="column is-6 is-flex is-horizontal-center is-hidden-mobile">
              <figure className="image is4by3 mt-2">
                <FaBusinessTime size={340} color={'#69c4a6'} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section'>
      <div className='container'>
        <h2 className='is-size-5 mb-2'>For any interest, please fill in the form below.</h2>
        <InvestorsForm />
      </div>
    </section>
  </div>
}

InvestorsPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default InvestorsPageTemplate
